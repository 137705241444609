import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";

const axios = require("axios").default;

async function fetchCampaigns() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/plans/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data.plans;
  } catch (err) {
    const error =
      `Error fetching Campaigns: ${err.response.data.message}` ||
      "An error occurred fetching Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchCampaign(id) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/plans/?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data.plan;
  } catch (err) {
    const error =
      `Error fetching Campaign: ${err.response.data.message}` ||
      "An error occurred fetching Campaign. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function addCampaign(payload) {
  const token = await getToken();
  let response;
  const { mode, data } = payload;
  try {
    if (mode === "brief") {
      response = await axios.post(`${baseUrl}/plans/brief`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else if (mode === "map") {
      response = await axios.post(`${baseUrl}/plans/map`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }

    if (response.data.data.message) {
      const message = `Added campaign successfully`;
      reportSuccess(message);
      return true;
    }
  } catch (err) {
    const error =
      `Error Adding Campaign: ${err.response.data.error}` ||
      `Error Adding Campaign: ${err.response.data.message}` ||
      "An error occurred adding Campaign. Please try again or contact the System Admin";
    handleHttpError(error);
    return false;
  }
}
async function updateCampaign() {}
async function deleteCampaign() {}

export {
  fetchCampaigns,
  fetchCampaign,
  addCampaign,
  updateCampaign,
  deleteCampaign,
};
