import { Ability, AbilityBuilder } from "@casl/ability";

export default function defineAbilityFor(userType) {
  const { can, build } = new AbilityBuilder(Ability);

  if (userType === "media_owner") {
    can("read", "billboards");
    can("read", "insights");
    can("read", "reset-password");
    can("read", "forgot-password");
    can("read", "not-found");
    can("read", "account-details");
    can("read", "login");
    can("read", "forbidden");
    can("read", "register");
  } else if (userType === "marketer") {
    can("read", "briefs");
    can("read", "insights");
    can("read", "reset-password");
    can("read", "forgot-password");
    can("read", "not-found");
    can("read", "account-details");
    can("read", "login");
    can("read", "forbidden");
    can("read", "register");
  } else if (userType === "media_planner") {
    can("read", "briefs");
    can("read", "campaigns");
    can("read", "monitoring");
    can("read", "insights");
    can("read", "reset-password");
    can("read", "forgot-password");
    can("read", "not-found");
    can("read", "account-details");
    can("read", "login");
    can("read", "forbidden");
    can("read", "register");
    can("read", "cart");
  } else if (userType === "other") {
    can("read", "insights");
    can("read", "reset-password");
    can("read", "forgot-password");
    can("read", "not-found");
    can("read", "account-details");
    can("read", "login");
    can("read", "forbidden");
    can("read", "register");
  }

  return build();
}
