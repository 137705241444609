import defineAbilityFor from "./abilities";
import { getUserDetails } from "@/helpers/utils";
import router from "../index";

export default async () => {
  const user = await getUserDetails();

  if (user) {
    const { userType } = user;
    const abilities = defineAbilityFor(userType);

    router.beforeEach((to, from, next) => {
      const canNavigate = to.matched.some((route) => {
        return abilities.can("read", route.meta.resource);
      });
      if (!canNavigate) {
        next("/forbidden");
        return;
      }
      next();
    });
  }
};
