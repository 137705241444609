import { handleHttpError, baseUrl, getToken } from "./utils";
const axios = require("axios").default;

async function fetchUserDetails() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const user_details = response.data.data;
    return user_details;
  } catch (err) {
    const error =
      `Error fetching user details: ${err.response.data.error}` ||
      "An error occurred fetching user details. Please try again or contact the system admin";

    return handleHttpError(error);
  }
}

export { fetchUserDetails };
