import { fetchAllPois, fetchPoiByBbox } from "@/helpers/pois";

export default {
  async fetchAllPois({ commit }) {
    const pois = await fetchAllPois();
    return commit("setPois", pois);
  },

  async fetchPoiByBbox({ commit }, payLoad) {
    const pois = await fetchPoiByBbox(payLoad);
    if (pois) return commit("setPoi", pois);
  },
};
