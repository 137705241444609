import { handleHttpError, baseUrl, getToken } from "./utils";

const axios = require("axios").default;

async function fetchAllPois() {}

async function fetchPoiByBbox({ xmin, ymin, xmax, ymax, poiType }) {
  const token = await getToken();

  try {
    const response = await axios.get(`${baseUrl}/poi/${poiType}`, {
      params: { xmin, ymin, xmax, ymax },
      headers: { Authorization: `Bearer ${token}` },
    });

    return { poiType: poiType, data: response.data.data };
  } catch (err) {
    const error =
      `Error fetching ${poiType}: ${err}` ||
      "An error occured fetching POIS. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

export { fetchAllPois, fetchPoiByBbox };
