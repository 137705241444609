import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";

const axios = require("axios").default;

async function fetchBriefs() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/briefs/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error =
      `Error fetching Briefs: ${err.response.data.error}` ||
      "An error occurred fetching Briefs. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchAllBriefs() {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/briefs`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error =
      `Error fetching Briefs: ${err.response.data.error}` ||
      "An error occurred fetching Briefs. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBrief(id) {
  try {
    const token = await getToken();

    const response = await axios.get(`${baseUrl}/briefs/?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error =
      `Error fetching Brief: ${err.response.data.error}` ||
      "An error occurred fetching Brief. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function addBrief(payLoad) {
  const token = await getToken();
  try {
    await axios.post(`${baseUrl}/briefs/`, payLoad, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = "Brief added successfully";
    return reportSuccess(message);
  } catch (err) {
    const message =
      `Error Uploading brief: ${err.response.data.error}` ||
      "An error was encountered trying to upload the brief. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function updateBrief(payLoad) {
  try {
    const token = await getToken();

    await axios.put(`${baseUrl}/briefs/${payLoad.id}`, payLoad, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = "Brief updated successfully";
    return reportSuccess(message);
  } catch (err) {
    const message =
      `Error updating brief: ${err.response.data.error}}` ||
      "An error was encountered trying to update the brief. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function deleteBrief(id) {
  try {
    const token = await getToken();

    await axios.delete(`${baseUrl}/briefs/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Brief deleted successfully`;
    return reportSuccess(message);
  } catch (err) {
    const error =
      `Error Deleting Brief: ${err.response.data.error}` ||
      "An error occurred Deleting Brief.Please try again or Contact the System Admin ";
    return handleHttpError(error);
  }
}

async function addBriefToCart({ commit }, payLoad) {
  const briefsCart = await addBriefToCart(payLoad);
  return commit("setBriefsCart", briefsCart);
}

async function removeBriefFromCart({ commit }, payLoad) {
  await removeBriefFromCart(payLoad);
  return commit("setBriefsCart", "");
}

export {
  fetchBriefs,
  fetchBrief,
  addBrief,
  updateBrief,
  deleteBrief,
  addBriefToCart,
  removeBriefFromCart,
  fetchAllBriefs,
};
