import {
  fetchBbsByBbox,
  fetchAllBbs,
  fetchPop,
  fetchBBPaginated,
  deleteBB,
  fetchBB,
  addBillboards,
  updateBillboard,
  addBbToCart,
  fetchBbCart,
  removeBbFromCart,
  searchBillboards,
  fetchBrands,
  fetchSuppliers,
  fetchMyBbs,
} from "@/helpers/billboards";

export default {
  async fetchAllBbs({ commit }) {
    const billboards = await fetchAllBbs();
    return commit("setBillboards", billboards);
  },

  async fetchMyBbs({ commit }) {
    const bb = await fetchMyBbs();
    return commit("setBillboards", bb);
  },

  async fetchBB(_, id) {
    const billboard = await fetchBB(id);
    return billboard;
  },

  async fetchPop({ commit }, { lat, long }) {
    const populationData = await fetchPop({ lat, long });
    return commit("setPopulation", populationData);
  },

  async fetchBbsByBbox({ commit }, payLoad) {
    const billboards = await fetchBbsByBbox(payLoad);
    return commit("setBillboards", billboards);
  },

  async fetchBBPaginated(_, payload) {
    const billboards = await fetchBBPaginated(payload);
    return billboards;
  },

  async addBillboards({ dispatch }, payload) {
    await addBillboards(payload);
    await dispatch("fetchMyBbs");
  },

  async updateBillboard({ dispatch }, payload) {
    await updateBillboard(payload);
    await dispatch("fetchMyBbs");
  },

  async deleteBB({ dispatch }, id) {
    await deleteBB(id);
    await dispatch("fetchMyBbs");
  },

  async addBbToCart({ commit }, id) {
    const bbCart = await addBbToCart(id);
    return commit("setBBToCart", bbCart);
  },

  async fetchBbCart({ commit }) {
    const bbCart = await fetchBbCart();
    return commit("setBBToCart", bbCart);
  },

  async removeBbFromCart({ commit }, id) {
    const bbCart = await removeBbFromCart(id);
    return commit("setBBToCart", bbCart);
  },

  async searchBillboards({ commit }, payLoad) {
    const results = await searchBillboards(payLoad);
    return commit("setSearchResults", results);
  },

  async fetchBrands({ commit }) {
    const results = await fetchBrands();
    return commit("setBrands", results);
  },

  async fetchSuppliers({ commit }) {
    const res = await fetchSuppliers();
    return commit("setSuppliers", res);
  },
};
