import {
  fetchCampaigns,
  fetchCampaign,
  addCampaign,
  updateCampaign,
  deleteCampaign,
} from "@/helpers/campaigns";

export default {
  async fetchCampaigns({ commit }) {
    const campaigns = await fetchCampaigns();
    return commit("setCampaigns", campaigns);
  },

  async fetchCampaign(_, id) {
    const campaign = await fetchCampaign(id);
    return campaign;
  },

  async addCampaign({ dispatch }, payLoad) {
    let response = await addCampaign(payLoad);
    await dispatch("fetchCampaigns");
    return response;
  },

  async updateCampaign(_, payLoad) {
    await updateCampaign(payLoad);
  },

  async deleteCampaign(id) {
    await deleteCampaign(id);
  },
};
