import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";
import {
  getFromLF,
  savetoLF,
  removeFromLF,
} from "../store/storage/localForage";

const axios = require("axios").default;

async function fetchAllBbs() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/billboards`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const billboards = response.data.data;

    return billboards;
  } catch (err) {
    const error =
      `Error fetching Billboards: ${err.response.data.error}` ||
      "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchMyBbs() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/billboards/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const billboards = response.data.data;

    await removeFromLF("billboards");

    await savetoLF("billboards", billboards);

    return billboards;
  } catch (err) {
    const error =
      `Error fetching Billboards: ${err.response.data.error}` ||
      "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBB(id) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/billboards/?id=${+id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error =
      `Error fetching Billboard: ${err.response.data.message}` ||
      "An error occurred fetching Billboard. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBbsByBbox({ xmin, ymin, xmax, ymax }) {
  // make api call
  // check if there is data in the local storage
  // get billboards with distinct ids
  //  replace them from ls
  const token = await getToken();

  try {
    const response = await axios.get(`${baseUrl}/billboards/bbox`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { xmin, ymin, xmax, ymax },
    });

    const billboards = response.data.data;

    return billboards;
  } catch (err) {
    const error =
      `Error fetching Billboards: ${err.response.data.error}` ||
      "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchPop({ long, lat }) {
  const token = await getToken();

  try {
    const response = await axios.get(`${baseUrl}/billboards/pop`, {
      params: { long, lat },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.data.data) {
      const error = `Population data not found`;
      return handleHttpError(error);
    }
    return response.data.data;
  } catch (err) {
    const error =
      `Error fetching Population: ${err.response.data.error}` ||
      "An error occured fetching Population. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function searchBillboards(payLoad) {
  try {
    const token = await getToken();
    const response = await axios.post(`${baseUrl}/billboards/search`, payLoad, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error =
      `Error searching billboards: ${err.response.data.error}` ||
      "An error occurred searching billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchBBPaginated(page) {
  const token = await getToken();

  try {
    // should check ls
    const response = await axios.get(`${baseUrl}/billboards/${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const billboards = response.data.data;

    // should save to ls

    return billboards;
  } catch (err) {
    const error =
      `Error fetching Billboards: ${err.response.data.error}` ||
      "An error occurred fetching Billboards. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function addBillboards(payload) {
  const token = await getToken();
  try {
    await axios.post(`${baseUrl}/billboards`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Added Billboard successfully`;
    return reportSuccess(message);
  } catch (err) {
    const message =
      `Error Uploading Billboard: ${err.response.data.error}` ||
      "An error was encountered trying to upload the billboard. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function updateBillboard(payload) {
  const token = await getToken();
  try {
    await axios.put(`${baseUrl}/billboards/${payload.id}`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Updated Billboard successfully`;
    return reportSuccess(message);
  } catch (err) {
    const message =
      `Error Uploading Billboard: ${err.response.data.error}` ||
      "An error was encountered trying to upload the billboard. Please try again or Contact the System Admin";
    return handleHttpError(message);
  }
}

async function deleteBB(id) {
  const token = await getToken();
  try {
    await axios.delete(`${baseUrl}/billboards/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const message = `Billboard deleted successfully`;
    return reportSuccess(message);
  } catch (err) {
    const error =
      `Error Deleting Billboard: ${err.response.data.error}` ||
      "An error occurred Deleting Billboard.Please try again or Contact the System Admin ";
    return handleHttpError(error);
  }
}

async function addBbToCart(id) {
  // add id to the bb cart[]
  let bbCart = await getFromLF("bbCart");
  if (bbCart) {
    if (bbCart.indexOf(id) === -1) {
      bbCart.push(id);
    }
    await savetoLF("bbCart", bbCart);
  } else {
    bbCart = [id];
    await savetoLF("bbCart", bbCart);
  }
  return bbCart;
}

async function fetchBbCart() {
  let bbCart = await getFromLF("bbCart");
  if (bbCart) return bbCart;
  return [];
}

async function removeBbFromCart(bbId) {
  let bbCart = await getFromLF("bbCart");

  if (!bbCart) {
    handleHttpError("The cart is currently empty");
    return [];
  }

  let idx = bbCart.indexOf(bbId);
  if (idx == -1) {
    handleHttpError("The billboard is not in the cart currently");
    return bbCart;
  } else {
    bbCart = bbCart.filter((id) => id !== bbId);

    await savetoLF("bbCart", bbCart);
    reportSuccess("Billboard removed from cart successfully");
    return bbCart;
  }
}

async function fetchBrands() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/billboards/brands`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const brands = new Set();
    response.data.data[0].brands.forEach((br) => {
      if (br) {
        brands.add(br);
      }
    });

    return [...brands];
  } catch (err) {
    const error =
      `
        Error fetching brands: ${err.response.data.error}` ||
      "An error occured fetching brands. Please try again or Contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchSuppliers() {
  try {
    const token = await getToken();
    const response = await axios.get(`${baseUrl}/billboards/suppliers`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const suppliers = new Set();
    response?.data?.data[0]?.suppliers?.forEach((suppl) => {
      if (suppl) {
        suppliers.add(suppl);
      }
    });
    return [...suppliers];
  } catch (err) {
    const error =
      `
        Error fetching suppliers: ${err?.response?.data?.error}` ||
      "An error occurred fetching suppliers. Please try again or Contact the System Admin";
    return handleHttpError(error);
  }
}

export {
  fetchBB,
  fetchPop,
  fetchAllBbs,
  fetchBbsByBbox,
  addBillboards,
  updateBillboard,
  fetchBBPaginated,
  deleteBB,
  addBbToCart,
  fetchBbCart,
  removeBbFromCart,
  searchBillboards,
  fetchBrands,
  fetchSuppliers,
  fetchMyBbs,
};
