import Vue from "vue";
import VueRouter from "vue-router";

import AuthRequired from "./guards/auth_required";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/briefs",
    component: () => import(/* webpackChunkName: "main" */ "@/pages"),
    children: [
      {
        path: "/briefs",
        name: "Brief",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "briefs" */ "@/pages/briefs/TheBrief.vue"
          ),
        meta: {
          resource: "briefs",
        },
      },
      {
        path: "/monitoring",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "monitoring" */ "@/pages/monitoring/BillboardsMonitoring.vue"
          ),
        meta: {
          resource: "monitoring",
        },
      },

      {
        path: "/campaigns",
        name: "Campaign",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "campaigns" */ "@/pages/campaign/TheCampaign.vue"
          ),
        meta: {
          resource: "campaigns",
        },
      },
      {
        path: "/cart",
        name: "Cart",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "cart" */ "@/pages/billboards/BillboardCart.vue"
          ),
        meta: {
          resource: "cart",
        },
      },
      {
        path: "/insights",
        name: "Insights",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "insights" */ "@/pages/insights/CompetitiveInsights.vue"
          ),
        meta: {
          resource: "insights",
        },
      },
      {
        path: "/editBBs",
        name: "EditBillboards",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "editBBs" */ "@/components/billboard/BillboardList.vue"
          ),
        meta: {
          resource: "editBBs",
        },
      },
      {
        path: "/billboards",
        name: "Billboards",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "billboards" */ "@/pages/media_owner/MediaOwner.vue"
          ),
        meta: {
          resource: "billboards",
        },
      },
      {
        path: "/account-details",
        name: "AccountDetails",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "account-details" */ "@/pages/user/AccountDetails.vue"
          ),
        meta: {
          resource: "account-details",
        },
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/pages/auth/RegisterClient.vue"
      ),
    meta: {
      resource: "register",
    },
  },
  {
    path: "/set-new-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/pages/auth/ResetPassword.vue"
      ),
    meta: {
      resource: "reset-password",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/pages/auth/ForgotPassword.vue"
      ),
    meta: {
      resource: "forgot-password",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/pages/auth/LoginClient.vue"),
    meta: {
      resource: "login",
    },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webChunkName: "forbidden" */ "@/pages/Forbidden.vue"),
    meta: {
      resource: "forbidden",
    },
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/pages/NotFound.vue"),
    meta: {
      resource: "not-found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
