import {
  fetchBriefs,
  fetchBrief,
  addBrief,
  updateBrief,
  deleteBrief,
  fetchAllBriefs,
} from "@/helpers/briefs";

export default {
  async fetchBriefs({ commit }) {
    const briefs = await fetchBriefs();
    return commit("setBriefs", briefs);
  },

  async fetchAllBriefs({ commit }) {
    const briefs = await fetchAllBriefs();
    return commit("setBriefs", briefs);
  },

  async fetchBrief(id) {
    const brief = await fetchBrief(id);
    return brief;
  },

  async addBrief(_, payLoad) {
    await addBrief(payLoad);
  },

  async updateBrief(_, payLoad) {
    await updateBrief(payLoad);
  },

  async deleteBrief(_, id) {
    await deleteBrief(id);
  },

  async addBriefToCart({ commit }, payLoad) {
    return commit("setBriefsCart", payLoad);
  },

  async removeBriefFromCart({ commit }) {
    return commit("setBriefsCart", "");
  },
};
