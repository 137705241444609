import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      // bb in general
      billboards: [],
      // pop within the bb area
      population: null,
      // bbs in cart
      bbCart: [],
      // within the users area
      within: [],
      // search results
      search: [],
      // brand names
      brands: [],
      // suppliers
      suppliers: [],
    };
  },
  mutations,
  getters,
  actions,
};
