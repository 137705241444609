import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      briefs: [],
      // currently handle one brief at a time
      briefsCart: null,
    };
  },
  mutations,
  getters,
  actions,
};
